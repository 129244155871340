.user_name {
  font-size: 20px;
  color: #136773;
  font-weight: 600;
  text-transform: capitalize;
  width: 90%;
  word-wrap: break-word;
  margin-left: 5%;
  margin-right: 5%;
}
.clinicBtnsWrapers {
  display: flex;
  flex-direction: row;
  gap: 22px;
}
.staff_id {
  font-size: 1rem;
  color: #919b9b;
  text-overflow: ellipsis;
  max-width: 17rem;
  align-items: center;
}

.staff_id_2 {
  font-size: 1rem;
  color: #fba622;
}

#clinicAdminTable {
  padding: 1.5rem 0 0.5rem 2rem;
  height: 90vh;
  position: fixed;
  width: 100%;
}

#clinicAdminTable1 {
  padding: 3% 0% 2% 0% !important;
}

.bg {
  background-color: rgb(246, 250, 251);
  height: 89vh;
  width: 80%;
  margin-left: auto;
}

.allTableHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;
}

.allTableTitle {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0px 20px;
  position: relative;
  width: 50%;
}
.allTableTitleClinic {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0px 20px;
  position: relative;
  width: 50%;
}
.countStyle {
  color: #919b9bb4;
  font-weight: 400;
  font-size: 18px;
  margin-left: 5px;
}

.innerSubFilters {
  padding: 0px 15px;
  display: flex;
  flex-direction: column;
}

.subFilters {
  display: flex;
  flex-direction: column;
}

.selectedFilter {
  text-transform: capitalize;
  background-color: #d7fdff;
  color: #2eacb2;
  font-weight: 500;
  border-radius: 20px;
  font-size: 16px;
  display: flex;
  align-items: center;
  padding: 4px 14px;
}

.crossIcon {
  margin-left: 8px;
  cursor: pointer;
}

.allClinicAdminTable {
  background-color: rgb(246, 250, 251);
  height: 80vh;
}

.filterPoper {
  position: absolute;
  background-color: white;
  padding: 40px;
  border-radius: 20px;
  top: 100%;
  right: 3%;
  font-weight: 500;
  font-size: 14px;
  color: #454238;
  z-index: 1400;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1), -2px -2px 5px rgba(0, 0, 0, 0.1);
}

.filterHead {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
}

.status_bottom {
  border-bottom: 1px solid;
}

.activityTable {
  background-color: rgb(246, 250, 251);
  height: 82vh;
  padding: 0 3% 1% 3%;
}

.iconAndHeading {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 1%;
  padding-right: 2%;
}
.button-container {
  display: flex;
  justify-content: space-between;
  width: 175px;
  border-radius: 17px;
  background-color: #b3b5b5;
}

.button {
  flex: 1;
  padding: 6px;
  text-align: center;
  cursor: pointer;
  border-radius: 17px;
}

.active {
  background-color: #136773;
  color: #fff;
  border: none;
  outline: none;
  font-size: 10px;
  font-weight: 400;
}

.inactive {
  background-color: transparent;
  color: white;
  border: none;
  outline: none;
  font-size: 9px;
  font-weight: 400;
}
#spaceBetween {
  justify-content: space-between !important;
}
.clientTableHead {
  font-weight: 600;
  padding: 30px !important;
  padding-left: 20px !important;
  font-size: 20px;
  color: #136773;
}
.pricing_btn_an {
  background-color: transparent;
  border-radius: 12px;
}
.invitePatientBtn {
  padding: 0px 15px 0px 15px;
  border: 2px solid #fba622;
  border-radius: 17px;
  color: #fba622;
  cursor: pointer;
}

.BackIconDiv {
  margin-left: 30px;
  height: 30px;
  cursor: pointer;
}

#disabledDate .react-datepicker-wrapper {
  cursor: not-allowed !important;
}

#disabledDate .react-datepicker__input-container input {
  cursor: not-allowed !important;
}

.tableRow {
  margin-top: 20px !important;
  cursor: pointer;
  width: 100% !important;
}

.tableRow:hover {
  background-color: #f6cf6437 !important;
  animation-name: fadeInOpacity;
  animation-timing-function: fade-in;
  animation-duration: 1s;
  cursor: pointer;
}

@keyframes fadeInOpacity {
  from {
    background-color: rgba(255, 255, 255, 0);
  }

  to {
    background-color: #f6cf6437 !important;
  }
}

.table_head {
  font-size: 14px !important;
  color: #919b9b !important;
}

.imgDivClinic {
  width: 40px;
  height: 40px;
  border-radius: 100px;
}

@media only screen and (max-width: 1640px) {
  .bg {
    height: 85.5vh;
  }

  .allClinicAdminTable {
    background-color: rgb(246, 250, 251);
    height: 79vh;
  }

  .activityTable {
    height: 77vh;
  }
}
@media only screen and (max-width: 1650px) {
  .allTableTitleClinic {
    width: 62%;
  }
}
@media only screen and (max-width: 1490px) {
  .allTableTitle {
    width: 64%;
  }
  .allTableTitleClinic {
    width: 74%;
  }
}
@media only screen and (max-width: 1440px) {
  .user_name {
    font-size: 16px;
  }

  .allTableHead {
    padding-top: 16px;
  }

  .staff_id {
    font-size: 0.9rem;
  }

  .user_detail {
    font-size: 0.7rem !important;
  }

  .sidebar_avatar {
    background-color: #136773 !important;
    width: 111px !important;
    height: 111px !important;
    font-size: 26px !important;
  }

  .clientTableHead {
    font-size: 14px;
    padding: 20px !important;
  }
}
