.stats_card_main_div {
  background-color: #fff;
  align-items: center;
  border-radius: 20px;
  width: 32%;
  overflow: hidden;
  scale: 0.9;
}

.stats_card_main_div2 {
  background-color: #fff;
  align-items: center;
  border-radius: 20px;
  width: 90%;
  overflow: hidden;
}

.stats_card_content_div {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  padding: 9% 0 5% 0;
  gap: 10px;
}
#technometerValRel {
  position: relative;
}
.technometerVal {
  position: absolute;
  top: 56px;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 11px;
  color: #919b9b;
  align-self: center;
}
#technometerVal2Rel {
  position: relative;
}
.technometerVal2 {
  position: absolute;
  top: 40px;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 11px;
  color: #919b9b;
  align-self: center;
}
#technometerVal3Rel {
  position: relative;
}
.technometerVal3 {
  position: absolute;
  top: 56px;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 11px;
  color: #919b9b;
  align-self: center;
}
.card_content_div2 {
  margin-top: 5px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.bottomStats {
  width: 100% !important;
}

.statsGraphics {
  height: 55px;
  max-width: 100%;
}

.timeLog {
  color: #454238;
  font-weight: 500;
  font-size: 14px;
}

.lastLog {
  color: #919b9b;
  font-weight: 400;
  font-size: 12px;
}

@media only screen and (max-width: 1440px) {
  .stats_card_content_div {
    padding-bottom: 0;
  }

  .statsGraphics {
    height: 50px;
  }

  .custom_icons {
    width: 50px;
  }
}

@media screen and (min-width: 1641px) {
  .technometerVal3 {
    top: 62px !important;
  }
}
