.screenshotReviewer-main-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}
.screenshotReviewer-image {
  max-width: 100%;
  max-height: 100%;
  border-radius: 7px;
  object-fit: cover;
}
.screenshotReviewer-image-close-btn {
  position: absolute;
  top: 9%;
  right: 3.6%;
  background: transparent;
  border: none;
  color: #fff;
  font-size: 50px;
  cursor: pointer;
}

.screenshotReviewer-image-next-btn {
  position: absolute;
  top: 50%;
  left: 3.6%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  color: #fff;
  font-size: 70px;
  cursor: pointer;
}

.screenshotReviewer-image-prev-btn {
  position: absolute;
  top: 50%;
  right: 3.6%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  color: #fff;
  font-size: 70px;
  cursor: pointer;
}
