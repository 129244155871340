.bgAddPrescriptionSessions {
  margin-top: 40px;
  background-color: #ffff;
  margin-left: 30px;
  border-radius: 20px;
  height: 46vh;
  max-width: 74vw;
  padding: 2rem 2rem 2rem 2rem;
}
.bgAddPrescriptionSessionsSimple {
  margin-top: 40px;
  background-color: #ffff;
  margin-left: 30px;
  border-radius: 20px;
  max-width: 74vw;
  padding: 2rem 2rem 1rem 2rem;
}
.arrows {
  cursor: pointer;
}

.SessionsHeading {
  display: flex;
  justify-content: space-between;
}

.leftside_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.backIcon_button {
  margin-right: 20px;
}

.dateTitle {
  margin-right: 20px;
  font-size: 18px;
  color: #919b9b;
  font-family: 'Poppins';
  font-weight: 500;
  line-height: 27px;
}

.dateExcercise {
  background-color: #f6fafb;
  padding: 6px;
  border-radius: 15px;
  color: #136773;
  font-family: 'poppins';
  font-size: 16px;
  font-weight: 500;
}

.excerciseDay {
  color: #fba622;
  font-weight: 500;
  font-size: 20px;
  font-family: 'Poppins';
  line-height: 30px;
}

.excercise_name_heading {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.excerciseNameIcon {
  margin-right: 10px;
}

.excercise_details {
  display: flex;
  max-width: 25vw;
  justify-content: space-between;
  height: 45px;
  margin-top: 30px;
}

.setNumber {
  width: 48%;
  background-color: #f6fafb;
  border-radius: 15px;
  align-items: center;
  display: flex;
  padding: 4%;
}

.text_rep_set {
  font-size: 12px;
  font-weight: 400;
  color: #919b9b;
}

.set_rep_valueNumber {
  font-size: 22px;
  margin-left: 8px;
  color: #136773;
  font-family: 'Poppins';
  font-weight: 600;
  line-height: 33px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.jointScoreHeading {
  margin-top: 20px;
  color: #136773;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 18px;
}

.jointScoreOuterDiv {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 70%;
}

.score_Bars {
  height: 155px;
  background-color: rgba(17, 162, 131, 0.05);
  width: 158px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.score_Bar1 {
  height: 160px;
  background-color: rgba(17, 162, 131, 0.05);
  width: 160px;
  border-radius: 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.performanceGraphDiv {
  /* height: 450px; */
  background-color: #fff;
  margin-top: 0.5%;
  width: 74vw;
  margin-left: 30px;
  margin-bottom: 3rem;
  border-radius: 15px;
  padding-bottom: 40px;
}

.screenShotDiv {
  height: 5rem;
  background-color: #fff;
  margin-top: 40px;
  width: 93%;
  margin-left: 30px;
  margin-bottom: 1.1rem;
  border-radius: 15px;
  flex-direction: column;
}

.screenShotTitleDiv {
  height: 5rem;
  border-radius: 15px;
  background-color: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 86%;
  color: #136773;
  font-size: 1.1rem;
  cursor: pointer;
}

.screenShotsPhotoCollapse {
  background-color: #fff;
  width: 93%;
  margin-left: 30px;
  border-radius: 15px;
}

.screenShotImagesDiv {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.4rem;
}

.screenShotImages {
  border-radius: 10px;
  width: 8rem;
  height: 6rem;
  object-fit: cover;
  cursor: pointer;
  transform: scaleX(-1);
  filter: FlipH;
}

.screenShotImages:hover {
  outline: 5px solid #136773;
}

.performanceTitle {
  padding-top: 40px;
  padding-left: 3.5%;
  color: #136773;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  padding-bottom: 20px;
}

.excercise_name_p {
  color: #136773;
  font-family: 'poppins';
  font-weight: 500;
  line-height: 24px;
  font-size: 16px;
}

.excercises_names {
  color: #000000;
  font-weight: 500;
  font-family: 'poppins';
  font-size: 12px;
  line-height: 18px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.excercise_percent {
  color: #000000;
  font-family: 'Poppins';
  font-weight: 500;
  line-height: 17px;
}

.scoreText {
  color: #919b9b;
  font-size: 8px;
  font-weight: 400;
  font-family: 'poppins';
  line-height: 12px;
}

.performanceName_colors {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
}

.colorDotsDiv {
  display: flex;
  flex-direction: row;
  width: 17%;
  align-items: center;
  justify-content: space-between;
  margin-right: 5%;
  margin-top: 20px;
}

.colorDots {
  display: flex;
  align-items: center;
  gap: 3px;
  margin-left: 5px;
}

.colortxt {
  font-size: 12px;
  color: #202a3e;
  font-family: 'Poppins';
  font-weight: 400;
  line-height: 18px;
}

.graphData {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}

.overallScoreGraph {
  position: absolute;
  max-height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.overAll_Arrows {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.colorDotsDiv2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media only screen and (max-width: 1750px) {
  .bgAddPrescriptionSessions {
    height: 30rem;
  }

  .excercise_details {
    max-width: 27rem;
  }

  .jointScoreOuterDiv {
    width: 80%;
  }

  .score_Bars {
    width: 139px;
  }
}

@media only screen and (max-width: 1500px) {
  .jointScoreOuterDiv {
    width: 90%;
  }

  .score_Bars {
    width: 135px;
  }

  .excerciseDay {
    font-size: 17px;
  }

  .jointScoreHeading {
    font-size: 16.5px;
  }

  .dateTitle {
    font-size: 16.5px;
  }

  .dateExcercise {
    font-size: 14.5px;
  }

  .set_rep_valueNumber {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1300px) {
  .jointScoreOuterDiv {
    width: 95%;
    gap: 10px;
  }

  .colortxt,
  .excercises_names {
    font-size: 9px;
  }

  .score_Bars {
    width: 130px;
  }
}
