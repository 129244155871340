.sideBarInfo {
  text-align: center;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sidebarSubDiv {
  display: flex;
  padding: 5% 0px;
  padding-left: 2%;
  flex-direction: column;
  gap: 1rem;
  width: 81%;
}

.sidebarSubDiv2 {
  display: flex;
  padding: 5% 0px;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.left_side {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1rem;
  padding: 8% 1%;
}

.sidebar_avatar {
  background-color: #136773 !important;
  width: 111px !important;
  height: 111px !important;
  font-size: 32px !important;
}

#gapAdd {
  gap: 1rem !important;
}

.user_detail {
  color: #919b9b;
  font-size: 0.9rem;
  display: table-cell;
  vertical-align: middle;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  min-width: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0rem !important;
}

.user_exp_detail {
  width: 70%;
  font-size: 0.9rem;
  margin: 0 auto;
  color: #ff5151;
  display: flex;
  gap: 10px;
}

.spaceRight {
  white-space: pre;
}

.patient_exp_detail {
  font-size: 0.9rem;
  margin: 0 auto;
  color: #ff5151;
  display: flex;
  gap: 5px;
}

.detail_expiry {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.activityTxt {
  margin-left: 13px;
  font-weight: 600;
  font-size: 16px;
  white-space: pre;
  color: #919b9b;
}

.clickedActivity {
  margin-left: 13px;
  font-weight: 600;
  font-size: 16px;
  color: #136773;
}

.mainAvatarDiv {
  display: flex;
  align-items: flex-end;
  padding-left: 7%;
  position: relative;
}

.editIconSidebar {
  position: absolute;
  right: 5%;
  cursor: pointer;
  bottom: 5%;
}

.totalActivity {
  font-weight: 600;
  font-size: 16px;
  color: #919b9b;
  margin-right: auto;
  margin-left: 15%;
  margin-top: 10%;
}

.hrsTxt {
  font-weight: 500;
  font-size: 14px;
  color: #fba622;
  margin-right: auto;
  padding: 0;
  margin-left: 15%;
  line-height: 1px;
}

.exerciseList {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2rem;
  margin: auto;
  width: 62%;
  list-style: none;
  padding-left: 10%;
}

.actionListIcon {
  margin-right: 20px;
}

.selectedItemExercise .actionListIcon {
  filter: brightness(0) saturate(100%) invert(31%) sepia(22%) saturate(1780%) hue-rotate(140deg)
    brightness(96%) contrast(85%);
  margin-right: 20px;
}

.divider {
  filter: brightness(0) saturate(100%) invert(31%) sepia(22%) saturate(1780%) hue-rotate(140deg)
    brightness(96%) contrast(85%);
}

.itemExercise {
  line-height: 40px;
  color: #919b9b;
  font-weight: 500;
  font-size: 14px;
  text-decoration: none;
}

.selectedItemExercise {
  line-height: 40px;
  color: #136773;
  font-weight: 500;
  font-size: 14px;
  text-decoration: none;
  text-transform: capitalize;
}

.dropdownIcons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.listDiv {
  width: 100%;
  margin-top: -13px;
  max-height: 12vh;
  overflow-y: scroll;
}
.listDiv::-webkit-scrollbar {
  width: 5px; /* width of the scrollbar */
}

.listDiv::-webkit-scrollbar-track {
  background: #f1f1f1; /* color of the track */
}

.listDiv::-webkit-scrollbar-thumb {
  background: #136773; /* color of the scrollbar */
}

.listDiv::-webkit-scrollbar-thumb:hover {
  background: #136773; /* color of the scrollbar on hover */
}

.activityLeft {
  display: flex;
  width: 70%;
  cursor: pointer;
  align-items: center;
  text-decoration: none;
}

.iconActivity {
  filter: brightness(0) saturate(100%) invert(31%) sepia(22%) saturate(1780%) hue-rotate(140deg)
    brightness(96%) contrast(85%);
}

.selectedExActivity {
  filter: brightness(0) saturate(100%) invert(31%) sepia(22%) saturate(1780%) hue-rotate(140deg)
    brightness(96%) contrast(85%);
  font-weight: 600;
  font-size: 16px;
  color: #136773;
  display: flex;
  width: 70%;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
}

.activityEx {
  display: flex;
  width: 70%;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  color: #919b9b;
}

.activityEx .exIcon,
.selectedExActivity .exIcon {
  margin-right: 10%;
  cursor: pointer;
}

.exTxt {
  cursor: pointer;
  width: fit-content;
}

.imgDivSide {
  width: 110px;
  height: 110px;
  border-radius: 100px;
}

.xnewww {
  width: 85%;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.chartDiv {
  width: 85%;
  margin-top: 10px;
  border-radius: 20px;
  border: 1px solid #efeded;
}

.exportBtn {
  color: #fba622;
  padding: 15px 30px 15px 30px;
  border-radius: 20px;
  width: 80%;
  background-color: #fba6221a;
  border: none;
  cursor: pointer;
}

@media only screen and (max-width: 1440px) {
  .spaceRight {
    font-size: 12px;
    white-space: unset;
  }

  .user_exp_detail {
    width: 72% !important;
  }

  .user_detail {
    font-size: 0.8rem !important;
  }

  .xnewww {
    width: 80% !important;
  }

  .activityTxt,
  .clickedActivity {
    margin-left: 8%;
    font-size: 12px;
  }
  .exerciseList {
    padding-left: 11%;
  }
}

@media only screen and (max-width: 1390px) {
  .user_exp_detail {
    width: 77% !important;
  }
}

@media only screen and (max-width: 1321px) {
  .user_exp_detail {
    width: 79% !important;
  }
}

@media only screen and (max-width: 1289px) {
  .user_exp_detail {
    width: 82% !important;
  }
}

@media only screen and (max-width: 1200px) {
  .user_detail {
    font-size: 0.7rem !important;
  }

  .sidebarSubDiv2 {
    width: 154px;
  }
}
@media only screen and (max-width: 1340px) {
  .selectedItemExercise,
  .itemExercise {
    font-size: 12px;
  }
}

@media only screen and (max-width: 1100px) {
  .selectedItemExercise,
  .itemExercise {
    font-size: 12px;
  }

  .iconActivity,
  .notActivity {
    width: 90%;
  }

  .table_header {
    font-size: 0.6rem !important;
  }

  .sidebarSubDiv2 {
    width: 142px;
  }
}
