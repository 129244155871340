.tableContainer::-webkit-scrollbar-thumb {
  background: rgba(19, 103, 115, 0.2);
  border-radius: 1e6px;
}

.tableContainer::-webkit-scrollbar-track {
  background: transparent;
}

.app_logo {
  width: 90%;
}

.header_main_div {
  background-color: #ffffff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 2rem;
  padding-right: 3.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: fixed;
  top: 0;
  z-index: 999;
}

.headerImgDiv {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.headerProfileImage {
  width: 100%;
  height: 100%;
  border-radius: 100px;
  object-fit: cover;
}

.header_avatar {
  background-color: #136773 !important;
  font-size: medium !important;
}

.header_main_avatar {
  background-color: #136773 !important;
  cursor: pointer !important;
}

.tab1_div {
  cursor: pointer;
}

.tab2_div {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f6fafb;
  border-radius: 20px;
  color: #136773;
  padding: 1rem;
  gap: 1rem;
}

.tab_icon {
  cursor: pointer;
}

.search {
  cursor: pointer;
}

.bellIcon {
  cursor: pointer;
}

.menu_icon_div {
  display: none;
}

.nav_icons_txt {
  font-weight: 500;
  font-size: 16px;
}

@media only screen and (max-width: 1440px) {
  .app_logo {
    width: 80%;
  }

  .nav_icons {
    width: 15%;
  }

  .nav_icons_txt {
    font-size: 14px;
  }

  .tab2_div {
    padding: 0.6rem;
  }

  .card_main_div {
    height: 90px;
  }

  .dashboard_div_2 {
    margin-top: 1rem;
  }
}

@media only screen and (max-width: 1284px) {
  .header_avatar {
    height: 25px !important;
    width: 25px !important;
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 992px) {
  .tab2_div span {
    font-size: 13px;
  }

  .tab2_div {
    padding: 12px;
  }
}

@media only screen and (max-width: 800px) {
  .tab2_div span {
    font-size: 11px;
  }

  .tab2_div {
    padding: 10px;
  }
}

@media only screen and (max-width: 700px) {
  .tab2_div {
    display: none;
  }

  .tab_icon {
    display: none;
  }

  .tab3_div {
    display: none;
  }

  .menu_icon_div {
    display: block;
  }

  .header_main_div {
    padding-right: 1rem;
  }
}
