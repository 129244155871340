.mainLoginDiv {
  height: 150px;
  display: flex;
}

.logoHeading {
  color: #fba622;
  font-weight: 600 !important;
  font-size: 32px;
  padding: 0px 12px;
}

.logoDiv {
  margin-left: 9%;
}

.loginScreenBG {
  background: #f6fafb;
  height: 100vh;
  position: fixed;
  width: 100%;
}

.forget-txt {
  width: fit-content;
  margin-left: auto;
}

.loginPgImg {
  position: absolute;
  bottom: 0;
  left: 30px;
}

.loginForm {
  background-color: white;
  padding: 4.5% 3%;
  border-radius: 20px;
  width: 29%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.loginformSubHeading {
  color: #919b9b;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 27px;
  text-align: center;
}

.loginformHeading {
  font-weight: 500;
  color: #454238;
  font-size: 32px;
  text-align: center;
  line-height: 48px;
}

.forgetPasswordTxt {
  color: #136773;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
}

.logInBtn {
  background-color: #fba622 !important;
  width: 100% !important;
  border-radius: 20px !important;
  padding: 3% 0px !important;
  box-shadow: none !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  text-transform: none !important;
}
.logInBtnloading {
  background-color: #dcdbd9 !important;
  width: 100% !important;
  border-radius: 20px !important;
  padding: 3% 0px !important;
  box-shadow: none !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  text-transform: none !important;
}

@media only screen and (max-width: 1200px) {
  .loginformHeading {
    font-size: 28px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1919px) {
  .mainLoginDiv {
    height: 90px;
  }

  .loginPgImg {
    width: 42%;
  }

  .loginPglogo {
    width: 50%;
  }

  .logoHeading {
    font-size: 22px;
  }

  .loginForm {
    padding: 4% 2.7%;
    width: 34%;
  }

  .loginformSubHeading {
    font-size: 16px;
  }
}

/*Login Page Mobile Responsiveness */
@media only screen and (max-width: 767px) {
  .loginPglogo {
    width: 70% !important;
    margin-top: -10% !important;
    margin-left: 50% !important;
  }
  .loginForm {
    width: 80% !important;
    right: 10% !important;
    top: 45% !important;
  }
  .loginformHeading {
    font-size: 24px !important;
  }
  .loginPgImg {
    height: 240px !important;
  }
}
@media only screen and (max-width: 376px) {
  .loginPglogo {
    width: 70% !important;
    margin-top: 10% !important;
    margin-left: 50% !important;
  }
  .loginForm {
    width: 72% !important;
    right: 14% !important;
    top: 44% !important;
  }
  .loginformHeading {
    font-size: 24px !important;
  }
  .loginPgImg {
    height: 150px !important;
  }
}
