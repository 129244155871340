/* Importing fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* Importing all CSS files */
@import url('./Header.css');
@import url('./cardsWithIcons.css');
@import url('./statsCard.css');
@import url('./Login.css');
@import url('./Popups.css');
@import url('./Sidebar.css');
@import url('./Report.css');
@import url('./Message.css');
@import url('./PainSurvey.css');
@import url('./SearchScreen.css');
@import url('./screenshotViewer.css');
/* pages */
@import url('./Pages/Dashboard.css');
@import url('./Pages/ClinicAdmin.css');
@import url('./Pages/Clinician.css');
@import url('./Pages/Patient.css');
@import url('./Pages/Session.css');
@import url('./Pages/ClinicianDashboard.css');
/* Defaults */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif !important;
}

/* Primary Variables */
:root {
  --primary: '#136773';
}

/* DEFAULT MEDIA QUERIES (copy from here)
@media only screen and (max-width: 1600px) {
}

@media only screen and (max-width: 1440px) {
}

@media only screen and (max-width: 1366px) {
}

@media only screen and (max-width: 992px) {
}

@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 600px) {
}

@media only screen and (max-width: 480px) {
}

*/
