a {
  text-decoration: none;
}

.patientScreenCardsDiv {
  width: 98%;
}

.navigationEntities {
  margin-right: 8px;
  cursor: pointer;
}

.chartWrapOnExerciseDetailsScreen {
  background-color: white;
  max-width: 74vw;
  border-radius: 22px;
  margin-left: 30px;
  margin-top: 40px;
  padding: 2rem;
}

.selectedExerciseNameOnChart {
  font-size: 16px;
  color: #136773;
  font-weight: 500;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.breadcrumbs {
  color: #919b9b;
  font-weight: 400;
  text-transform: capitalize;
}

.cards_div_patient_pg {
  display: flex;
  flex-direction: row;
  text-align: left;
  justify-content: space-between;
  margin-top: 1%;
  margin-left: 1.5%;
  gap: 10px;
}

.right_sidebar_container {
  background-color: rgb(246, 250, 251);
  padding-right: 55px;
}

.right_sidebar {
  width: 17vw;
  height: 100vh;
  padding: 23% 0;
}

.right_sidebar_card {
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: row;
  border-radius: 20px;
  padding: 10px;
  margin-bottom: 3%;
}

.prescription_table_cells {
  font-size: 1rem !important;
  font-weight: 400 !important;
  color: #919b9b !important;
}

.prescriptionBtns {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.prescriptionBtns2 {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  font-weight: 500;
  margin-top: 1rem;
}

.thresholdsBtn {
  border: 2px solid #ff5151 !important;
  padding: 12px 45px !important;
  border-radius: 20px !important;
  color: #ff5151 !important;
  background-color: white !important;
  text-transform: none !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  margin-right: 20px !important;
}

.listBtn {
  width: 100%;
  padding: 10px 0px !important;
  color: white !important;
  background-color: #136773 !important;
  text-transform: none !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  border-radius: 15px !important;
}

.listBtn_disabled {
  width: 100%;
  padding: 10px 0px !important;
  border-radius: 15px !important;
  color: white !important;
  background-color: gray !important;
  text-transform: none !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}

.listTxtBtn {
  margin-right: 10px;
}

.top_activity_cards {
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: row;
  border-radius: 20px;
  width: 23%;
  padding: 10px;
  margin-bottom: 3%;
  gap: 10px;
}

.patientListChart {
  background-color: white;
  height: max-content;
  padding: 10% 6%;
  margin: 0 0 8% 0;
  border-radius: 20px;
}

.timeChartDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.chartTime {
  color: #fba622;
  font-weight: 500;
  font-size: 18px;
}

.chartSubtxt {
  color: #136773;
  font-weight: 400;
  font-size: 10px;
}

.chartTimeTxt {
  color: #919b9b;
  font-weight: 500;
  font-size: 14px;
}

.currentTimeStatsDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 4% 0;
}

.currentTimeSubDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: auto;
}

.chartDayTxt {
  color: #fba622;
  font-weight: 500;
  font-size: 11px;
}

.chartCurrentTime {
  color: #136773;
  font-weight: 400;
  font-size: 11px;
}

.periodDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 3%;
}

.periodSelect {
  color: #fba622;
  font-weight: 600;
  font-size: 16px;
  padding: 3%;
}

.periodSelect:hover {
  color: white;
  background-color: #fba622;
  border-radius: 7px;
  cursor: pointer;
}

.circularBarCell {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  position: fixed;
  top: 0px;
  bottom: 20px;
  right: 1;
}

.circularBarCellAllPatients {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.percentageValue {
  position: absolute;
  right: 46%;
  bottom: 52%;
}

.circularProgress {
  transform: rotate(90deg) !important;
  position: absolute;
  bottom: 0%;
  right: 0%;
  top: 100%;
  bottom: 50px;
  left: 0px;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  color: #454238;
}

.percentageData {
  position: absolute;
  bottom: 36%;
  right: 32%;
}

.circularProgressPrescription {
  transform: rotate(90deg) !important;
  position: absolute;
  bottom: 40%;
  right: 43%;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  color: #454238;
}

.bgPrescription {
  background-color: rgb(246, 250, 251);
  height: max-content;
  width: 80%;
  margin-left: auto;
}

.bgAddPrescription {
  padding: 2rem 2rem 2rem 2rem;
  width: 98%;
}

.prescriptionHead {
  color: #136773;
  font-weight: 500;
  font-size: 20px;
  padding-bottom: 13px;
}

.optColor {
  color: #919b9b;
  font-size: 14px;
  font-weight: 400;
}

.image_div {
  display: flex;
  align-items: center;
  width: 25px;
}

.selctedPresChoice {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f6fafb;
  color: #136773;
  font-weight: 500;
  font-size: 14px;
  border-radius: 10px;
  padding: 10px 13px;
  white-space: pre;
}

#prescriptionTableHead {
  font-weight: 400 !important;
}

.PrescriptionTableHead {
  font-weight: 500;
  padding: 20px !important;
  font-size: 18px;
  color: #919b9b;
  text-align: center;
}

.linearProgress {
  font-size: 12px;
  font-weight: 400;
  color: #454238;
}

.actionIcon {
  filter: brightness(0) saturate(100%) invert(31%) sepia(22%) saturate(1780%) hue-rotate(140deg)
    brightness(96%) contrast(85%);
  margin-right: 8px;
}

.actionDiv {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.linearPresProgress {
  font-size: 12px;
  font-weight: 400;
  color: #454238;
  margin-left: -14px;
}

.patient_cell {
  font-size: 1rem !important;
  font-weight: 500 !important;
  color: #454238 !important;
  margin-left: 8px;
  align-self: center;
}

#completion_cell {
  color: #fba622 !important;
  font-weight: 500 !important;
}

.notiCell {
  display: flex !important;
  flex-direction: row !important;
  text-align: center;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
}

.reportTxt {
  color: #ff5151;
  margin-left: 5%;
}

.statusTxt {
  color: #11a283;
  margin-left: 5%;
}

.msgTxt {
  color: #fba622;
  margin-left: 6px;
}

.clrTableRow {
  background-color: #f6cf6437 !important;
  animation-name: 'fadeInOpacity';
  animation-timing-function: 'fade-in';
  animation-duration: 1s;
}

.filter__multi-value.css-1p3m7a8-multiValue {
  border-radius: 20px !important;
  padding: 3px 0 !important;
  background-color: #f4f4f4 !important;
}

.addMore {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  color: #919b9b;
  cursor: pointer;
}

#prescriptionTable {
  margin: auto;
  padding: 2% 3% 0px 3%;
  height: 64%;
  overflow-y: auto;
  margin-top: 20px;
  overflow-x: hidden;
}

.scoreRed {
  color: #ff5151;
  width: max-content;
  padding: 8px 20px !important;
  background-color: #ff51511a;
  border-radius: 20px;
}

.scoreYellow {
  color: #fabb18;
  width: max-content;
  padding: 8px 20px !important;
  background-color: #fabb181a;
  border-radius: 20px;
}

.scoreGreen {
  color: #25cc7c;
  width: max-content;
  padding: 8px 20px !important;
  background-color: #25cc7c1a;
  border-radius: 20px;
}

.prescription_header {
  color: #136773;
  font-weight: 500;
  padding: 1.2% 2%;
}

.vertical_middle {
  vertical-align: middle !important;
}

.prescription_head {
  font-size: 15px !important;
  color: #454238 !important;
  padding-top: 4px;
}

.prescription_head_title {
  font-size: 16px !important;
  color: #454238 !important;
  padding-top: 4px;
}

.prescription_cell {
  color: #136773;
  font-weight: 500;
  font-size: 12px;
  margin-left: 10px;
  line-height: 14px;
}

.presSubtxt {
  color: #fba622;
  font-weight: 400;
  font-size: 10px;
}

.PatientSubTopSection {
  display: flex;
  flex-direction: row;
  width: calc(100% / 1.2);
  flex-wrap: wrap;
  align-content: flex-start;
}

.PatientTopSection {
  height: max-content;
  width: 76vw;
  border-radius: 20px;
  margin: auto;
  margin-top: 3%;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2%;
}

.topSec {
  display: flex;
  align-items: center;
  width: max-content;
  margin-right: 40px;
  margin-bottom: 10px;
  justify-content: flex-start;
  gap: 0.5rem;
  min-width: 14rem;
}

.topSec_in_clinician {
  display: flex;
  align-items: center;
  width: max-content;
  margin-right: 40px;
  margin-bottom: 10px;
  justify-content: flex-start;
  gap: 0.5rem;
  min-width: 14rem;
}

.topSec:hover .top_sec_avatar_disabled {
  background-color: #136773;
}

.topSec:hover .topSecDocName_disabled {
  color: #136773;
}

.topSec:hover .topSecExpertise_disabled {
  color: #fba622;
}

.top_sec_avatar {
  background-color: #136773 !important;
  height: 52px !important;
  width: 52px !important;
}

.top_sec_avatar_disabled {
  background-color: #919b9b;
  height: 52px !important;
  width: 52px !important;
}

.topSecDocName {
  font-weight: 600;
  font-size: 16px;
  color: #136773;
  text-transform: capitalize;
}

.topSecDocName_disabled {
  font-weight: 600;
  font-size: 16px;
  color: #919b9b;
  text-transform: capitalize;
}

.topSecExpertise {
  font-weight: 500;
  font-size: 12px;
  color: #fba622;
}

.topSecExpertise_disabled {
  font-weight: 500;
  font-size: 12px;
  color: #919b9b;
}

.topSecActions {
  font-weight: 400;
  font-size: 12px;
  color: #919b9b;
}

.exerciseItems li {
  list-style: none;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 15px !important;
  color: #454238 !important;
}

.excerciseItemHeading {
  padding-bottom: 4px !important;
}

.customOptions {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: max-content;
  background-color: #f4f4f4;
  border-radius: 20px;
  padding: 8px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.customOptions:hover {
  background-color: #cbcbcb76;
}

.opt_avatar {
  background-color: #136773 !important;
  height: 20px !important;
  width: 20px !important;
  font-size: 12px !important;
}

.optName {
  font-weight: 500;
  font-size: 12px;
  color: #919b9b;
  margin-left: 6px;
}

.OptNames {
  display: flex;
  flex-wrap: wrap;
}

.customSelect {
  border: 1px solid #e1e1e1;
  color: #919b9b;
  padding: 3% 2% 2% 3%;
  border-radius: 18px;
  text-align: left;
  height: max-content;
  margin-bottom: 2%;
  display: flex;
  flex-wrap: wrap;
}

.generatePrescriptionBtn {
  background-color: #fba622 !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  text-align: center !important;
  color: #f6fafb !important;
  line-height: 24px;
  text-transform: none !important;
  border-radius: 15px !important;
  border: none !important;
  padding: 8px 20px !important;
  width: 13rem !important;
}

.generatePrescriptionBtn_diaabled {
  background-color: gray !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  text-align: center !important;
  color: #f6fafb !important;
  text-transform: none !important;
  border-radius: 15px !important;
  border: none !important;
  padding: 8px 20px !important;
  width: 15rem !important;
}

.dateDivPrescription .react-datepicker-wrapper {
  border: 1px solid #e1e1e1 !important;
  background-color: white;
  border-radius: 20px;
  margin-top: 10px;
  padding: 1.8%;
  width: 100%;
}

.addPrescription {
  padding: 2rem 1.5rem 2rem 1.5rem;
  background-color: white;
  border-radius: 20px;
}

.gridish {
  display: flex;
  margin-top: 9vh;
  width: 100%;
}

.sideBar_div {
  width: 20%;
  position: fixed;
}

.addPrescriptionBtn {
  display: flex;
  justify-content: flex-end;
  margin-top: 5%;
  width: 100%;
}

.prescDetailsDiv {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-bottom: 20px;
}

.leftDetails {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.Exersice_main_div {
  display: flex;
  justify-content: space-between;
}

.left_exercise {
  width: 53%;
}

.prescTable {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 55%;
}

.sets_div {
  display: flex;
}

.calenderIcon {
  position: absolute;
  top: 6px;
  bottom: 0;
  margin: auto 0;
  right: 15px;
}

.calenderIconExport {
  position: absolute;
  top: 6px;
  bottom: 0;
  margin: auto 0;
  right: 54%;
}

.prescriptionChoiceSec {
  margin-bottom: 2%;
  cursor: pointer;
  gap: 20px;
  display: flex;
  justify-content: space-between;
  width: max-content;
}

.presChoice {
  white-space: pre;
  display: flex;
  align-items: center;
  color: #919b9b;
  font-weight: 500;
  padding: 10px 13px;
  font-size: 14px;
}

.iconClicked {
  filter: brightness(0) saturate(100%) invert(31%) sepia(22%) saturate(1780%) hue-rotate(140deg)
    brightness(96%) contrast(85%);
  margin-right: 8px;
}

.choiceIcon {
  margin-right: 8px;
}

.patientFilterPoper {
  position: absolute;
  background-color: white;
  padding: 40px;
  border-radius: 20px;
  top: 100%;
  right: 15%;
  font-weight: 500;
  font-size: 14px;
  color: #454238;
  height: 70vh;
  overflow-y: scroll;
  z-index: 99;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1), -2px -2px 5px rgba(0, 0, 0, 0.1);
}

.actionMenus {
  font-family: 'poppins';
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.pIcon {
  margin-right: 8px;
}

.menuItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 4px 0;
}

.editMenuItem {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 4px 0;
}

.clinicianDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
  width: max-content;
  border-radius: 1000px;
  cursor: pointer;
  padding: 0.5rem;
  margin-bottom: 13px;
}

.circleAround {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  width: 38px;
  border: 1.5px solid #faa017;
  border-radius: 50%;
  background-color: transparent;
}

.selectedClinicianDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
  width: max-content;
  border: 1px solid #fba622;
  background-color: rgba(251, 166, 34, 0.05);
  color: white;
  border-radius: 1000px;
  cursor: pointer;
  padding: 0.5rem;
  margin-bottom: 13px;
}

.selectedClinicianDiv p {
  font-weight: 500;
  font-size: 17px;
  color: #136773;
  text-transform: capitalize;
  margin-right: 6px;
}

.clinicianDiv p {
  font-weight: 500;
  font-size: 17px;
  color: #919b9b;
  text-transform: capitalize;
}

.clinicianParentDiv {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 20px;
  min-width: 18rem;
}

.main_div_addPrescription {
  background-color: #f6fafb;
  width: 100%;
}

.clinicianDP {
  margin-right: 10px;
  width: 25%;
}

.NoPrescriptionTxt {
  text-align: center;
  font-weight: 300;
  color: #919b9b;
  font-size: 32px;
}

.prescriptions_btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 46%;
}

.dateDivPrescription .react-datepicker-wrapper {
  margin-top: 11px;
  padding: 13.5px 14px;
}

.btn_div {
  width: 26%;
}

@media only screen and (max-width: 1810px) {
  .periodSelect {
    padding: 1%;
  }

  .prescriptions_btns {
    width: 52%;
  }

  .css-ahj2mt-MuiTypography-root {
    font-size: 11px;
  }
}

@media only screen and (max-width: 1600px) {
  .bgPrescription {
    padding-bottom: 40px;
  }

  .actionIcon {
    margin-right: 6px;
  }

  .actionDiv,
  .prescription_table_cells {
    font-size: 12px !important;
  }

  .generatePrescriptionBtn {
    font-size: 12px !important;
    padding: 13px 20px !important;
    position: absolute;
    top: 25px;
  }

  #prescriptionTableHead {
    font-size: 12px !important;
  }

  .listBtn,
  .thresholdsBtn {
    padding: 10px 0px !important;
    font-size: 14px !important;
  }

  .css-ahj2mt-MuiTypography-root {
    font-size: 11px !important;
  }

  .periodSelect {
    color: #fba622;
    font-weight: 600;
    font-size: 12px;
    padding: 3%;
  }

  .patientChart {
    padding-right: 6%;
  }

  .patient_cell {
    font-size: 0.9rem !important;
  }

  .circularProgress {
    right: 40%;
  }

  .prescriptions_btns {
    justify-content: start;
  }
}

@media only screen and (max-width: 1536px) {
  .notiCell {
    font-size: 12px;
    width: 140px;
    padding-left: 5px;
  }

  .prescriptionMenu {
    right: 5px;
  }

  .topSecDocName {
    font-size: 14px;
    font-weight: 600;
  }

  .topSecDocName_disabled {
    font-size: 14px;
    font-weight: 600;
  }

  .topSecExpertise {
    font-size: 11px;
  }

  .topSecExpertise_disabled {
    font-size: 11px;
  }

  .top_sec_avatar {
    height: 50px;
    width: 50px;
  }

  .top_sec_avatar_disabled {
    height: 50px;
    width: 50px;
  }

  .topSec {
    min-width: 13rem;
  }

  .selectedClinicianDiv {
    max-height: 4.3rem;
    min-width: 10rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 15px;
  }

  .selectedClinicianDiv p {
    font-size: 15px;
  }

  .NoPrescriptionTxt {
    font-size: 26px;
  }
}

@media only screen and (max-width: 1536px) {
  .prescriptionHead {
    font-size: 17px;
  }

  .PrescriptionTableHead {
    font-size: 16px;
  }

  .selectedOpt {
    font-size: 13px;
  }
}

@media only screen and (max-width: 1420px) {
  .PatientTopSection {
    width: 73vw !important;
  }

  .prescription_head {
    font-size: 13px !important;
  }

  .prescription_head_title {
    font-size: 14px !important;
  }

  .exerciseItems li {
    font-size: 13px !important;
  }
}

@media only screen and (max-width: 1440px) {
  .sets_div {
    flex-direction: column;
  }

  .right_sidebar {
    padding: 28% 0;
  }

  .generatePrescriptionBtn_diaabled {
    width: 45% !important;
    font-size: 12px !important;
    white-space: pre !important;
  }

  .listTxtBtn {
    font-size: 12px;
    white-space: pre;
  }

  .left_exercise {
    width: 50%;
  }

  .prescTable {
    width: 50%;
  }

  .clientTableHead {
    font-size: 16px;
  }

  .table_head {
    font-size: 12px !important;
  }

  .css-1ex1afd-MuiTableCell-root {
    font-size: 0.675rem;
  }

  .name_cell_dasdboard,
  .table_cells {
    font-size: 0.7rem !important;
  }

  .periodSelect {
    font-size: 11px;
  }

  .css-ymgmpz-MuiFormControl-root {
    width: 31% !important;
  }

  .btn_div {
    width: 40%;
  }

  .listBtn_disabled {
    padding: 10px !important;
    font-size: 12px !important;
    white-space: pre;
  }

  .thresholdsBtn {
    border-radius: 9px !important;
  }
}

@media only screen and (max-width: 1284px) {
  .css-1ex1afd-MuiTableCell-root {
    font-size: 0.6rem !important;
  }

  .name_cell_dasdboard {
    font-size: 9px !important;
  }

  .periodSelect {
    font-size: 9.5px;
  }

  .prescription_head {
    font-size: 11px !important;
  }

  .exerciseItems li {
    font-size: 11px !important;
  }

  .table_header {
    font-size: 0.7rem !important;
  }
}

@media only screen and (max-width: 1200px) {
  .css-18y2n7r-MuiGrid-root {
    width: 43%;
  }

  .css-mgdjos-MuiGrid-root {
    width: 49%;
  }

  .css-ymgmpz-MuiFormControl-root {
    width: 31% !important;
  }

  .css-ahj2mt-MuiTypography-root {
    font-size: 10px !important;
  }

  .selectedOpt {
    font-size: 12px;
  }

  .name_cell_dasdboard,
  .table_cells {
    font-size: 0.6rem !important;
  }
}

@media only screen and (max-width: 1100px) {
  .iconTimeClass {
    width: 25%;
  }

  .name_cell_dasdboard {
    font-size: 7px !important;
  }

  .timeLog,
  .activityTxt,
  .clickedActivity {
    font-size: 10px;
  }

  .lastLog {
    font-size: 11px;
  }

  .periodSelect {
    font-size: 8.5px;
  }

  .css-1s46rkj-MuiGrid-root {
    width: 51%;
    margin-left: 2%;
  }

  .css-1sy7m84-MuiFormControl-root-MuiTextField-root input {
    margin-left: 0px;
    font-size: 10px;
  }

  .css-ymgmpz-MuiFormControl-root {
    width: 30% !important;
  }

  .css-ahj2mt-MuiTypography-root {
    font-size: 8px !important;
  }
}
