.chat-box {
  height: 78vh;
  background-color: white;
  border-radius: 20px;
  padding: 30px;
  position: relative;
}

.msgSendBtn {
  margin-top: 2rem;
  cursor: pointer;
}

.noDataTxt {
  color: rgba(145, 155, 155, 1) !important;
}

.receiver {
  text-align: left;
  padding: 20px;
  padding-right: 35%;
  display: flex;
  flex-direction: column;
}

.receivrInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.iconsSec {
  width: fit-content;
  display: flex;
  align-items: center;
}

.receiverName {
  margin-left: 15px;
  font-weight: 500;
  font-size: 16px;
}

.receiverMsg {
  font-weight: 400;
  font-size: 12px;
  color: #454238;
  margin-left: 65px;
}

.sender {
  text-align: right;
  padding: 20px;
  padding-left: 35%;
  display: flex;
  flex-direction: column;
}

.senderInfo {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-end;
}

.sender_name_msg {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  gap: 5px;
}

.senderName {
  font-weight: 500;
  font-size: 16px;
  color: #136773;
}

.senderMsg {
  font-weight: 500;
  font-size: 12px;
  color: #454238;
}

.chat-box__messages {
  height: 55vh;
  overflow-y: auto;
}

.chat-box__messages::-webkit-scrollbar {
  display: none;
}

.chat-header {
  padding: 10px;
}

.receiverHeaderName {
  font-weight: 500;
  font-size: 18px;
  margin-left: 10px;
}

.patientId {
  font-weight: 500;
  font-size: 14px;
  color: #919b9b;
  margin-left: 10px;
}

.headerInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.backBtn {
  margin-right: 20px !important;
  cursor: pointer;
}

.bgMsg {
  background-color: rgb(246, 250, 251);
  margin-left: auto;
  width: 80%;
}

.emoji-open {
  position: absolute;
  bottom: 100%;
  right: 5%;
}

@media only screen and (max-width: 1600px) {
  .chat-box__messages {
    height: 50vh;
  }

  .chat-box {
    padding: 35px;
  }

  .receiver {
    padding-right: 20%;
  }

  .sender {
    padding-left: 20%;
  }
}
