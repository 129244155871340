.dashboard_main_div {
  width: 100vw;
  background-color: #f6fafb;
  padding: 1.5rem 4rem 0 4rem;
  position: fixed;
  height: 100vh;
  margin-top: 9vh;
}

.navigation {
  padding: 2rem 0;
  color: #919b9b;
  width: 100%;
  font-size: 15px;
}

.name_cell_dasdboard {
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #454238 !important;
  margin-left: 8px;
  text-transform: capitalize !important;
  display: table-cell !important;
}

.staffEmailCell {
  font-size: 14px !important;
  font-weight: 400 !important;
  text-transform: none !important;
}

.dashboardChartTxt {
  font-weight: 500;
}

.cards_div {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 0.7rem;
}

.dashboard-table-txt {
  color: #136773;
  font-weight: 500;
}

.dashboard_table_div {
  width: 62%;
  margin-top: 2rem;
  height: 60vh;
}

.dashboard_table_head_btn {
  cursor: pointer !important;
  font-size: 0.9rem !important;
  color: #fba622 !important;
  background-color: white !important;
  border: 2px solid #fba622 !important;
  border-radius: 20px !important;
  padding: 1px 15px !important;
  text-transform: capitalize !important;
}

.table_header {
  font-size: 0.8rem !important;
  color: #919b9b !important;
}

.table_action_div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.table_icons {
  cursor: pointer;
  z-index: 5;
}

.dashboard_div_2 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 1.5rem;
}

.chart_main_div {
  height: 423px;
  width: 35%;
  background-color: #fff;
  border-radius: 20px;
  padding: 2rem;
  margin-top: 2rem;
}

.chart_header_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2.7rem;
}

.chart_header_span {
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.chart_header_orange_dot {
  width: 7.87px;
  height: 7.87px;
  background: #fabb18;
  border-radius: 50%;
}

.table_cells {
  font-size: 1rem !important;
  font-weight: 400 !important;
  color: #454238 !important;
}

.table_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}

.addBtn {
  position: fixed !important;
  right: 3% !important;
  bottom: 7% !important;
  z-index: 1300 !important;
}

.addBtn2 {
  position: fixed !important;
  right: 3% !important;
  bottom: 5% !important;
  z-index: 1300 !important;
}

.imgDiv {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.action_btn {
  border-radius: 20px;
  padding: 5px 10px;
  border: 2px solid #fba622;
  color: #fba622;
  background: none;
  cursor: pointer;
}

@media only screen and (max-width: 1440px) {
  .name_cell_dasdboard,
  .table_cells {
    font-size: 0.9rem !important;
  }

  .dashboard_table_div {
    height: 53vh;
  }

  .chart_main_div {
    height: 350px;
  }

  .imgDiv {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .title {
    font-size: 10px;
  }
}

@media only screen and (max-width: 1284px) {
  .chart_main_div {
    width: 35%;
  }

  .css-186xryw-MuiPaper-root-MuiTableContainer-root {
    width: 56vw !important;
  }

  .table_title {
    font-size: 12px;
  }

  .dashboard_table_head_btn {
    font-size: 0.7rem !important;
  }

  .chart_header_div {
    font-size: 12px;
  }

  .dashboard_div_2 {
    margin-top: 1rem;
  }
}

@media only screen and (max-width: 1284px) {
  .chart_main_div {
    width: 34%;
    width: 36%;
  }

  .staffEmailCell {
    font-size: 12px !important;
  }

  .dashboard_main_div {
    padding: 1.5rem 2rem 0rem 2rem;
  }
}

@media only screen and (max-width: 992px) {
  .navigation {
    font-size: 13px;
  }
}

@media only screen and (max-width: 700px) {
  .navigation {
    font-size: 11px;
  }

  .cards_div {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 500px) {
  .table_title h3 {
    font-size: 1rem;
  }

  .dashboard_table_head_btn {
    font-size: 0.9rem;
  }

  .chart_header_div span {
    font-size: 0.8rem;
  }
}

@media only screen and (max-width: 340px) {
  .table_title h3 {
    font-size: 0.9rem;
  }
}

/* Customize ScrollBar */
@supports (-webkit-appearance: none) and (not (overflow: -webkit-marquee)) {
  ::-webkit-scrollbar {
    height: 0px;
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #ece7e7;
    border-radius: 25px 25px 25px 25px;
  }

  ::-webkit-scrollbar-thumb {
    background: #999797;
    border-radius: 25px 25px 25px 25px;
    width: 1px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #999797;
  }
}
