.yellow_circle {
  color: rgba(250, 187, 24, 0.12);
}

.red_circle {
  color: rgba(255, 81, 81, 0.12);
}

.green_circle {
  color: rgba(37, 204, 124, 0.12);
}

.blue_circle {
  color: rgba(13, 170, 220, 0.12);
}

.card_main_div {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1rem;
  border-radius: 20px;
  width: 23%;
  overflow: hidden;
}

.detail_card_main_div {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1rem;
  border-radius: 20px;
  width: 31%;
  overflow: hidden;
}

.detail_card_staff_dashboard {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1rem;
  border-radius: 20px;
  width: 23.5%;
  overflow: hidden;
}

.card_main_div2 {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1rem;
  border-radius: 20px;
  width: 30%;
  overflow: hidden;
}

.card_content_div {
  margin-left: 10px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.amount {
  font-size: 1.4rem;
  font-weight: 600;
}

.title {
  font-size: 14px;
  color: #919b9b;
  width: auto;
  margin-right: 5px;
}

.whiteSpace {
  white-space: pre;
}

@media only screen and (max-width: 1440px) {
  .amount {
    font-size: 1.1rem;
    font-weight: 600;
  }

  .title {
    font-size: 0.8rem;
    color: #919b9b;
  }

  .iconClass {
    min-width: 45px;
    min-width: 45px;
  }

  .yellow_circle,
  .red_circle,
  .green_circle,
  .blue_circle {
    height: 100px;
    width: 96px;
    min-width: 120px;
  }
}

@media only screen and (max-width: 1284px) {
  .yellow_circle,
  .red_circle,
  .green_circle,
  .blue_circle {
    height: 90px;
    min-width: 90px;
  }

  .iconClass {
    min-width: 45px;
  }
}

@media only screen and (max-width: 880px) {
  .card_main_div {
    width: 24%;
  }

  .amount {
    font-size: 1rem;
    font-weight: 600;
  }

  .title {
    font-size: 0.8rem;
    color: #919b9b;
  }
}

@media only screen and (max-width: 700px) {
  .card_main_div {
    width: 80%;
    margin-bottom: 10px;
  }

  .amount {
    font-size: 1.2rem;
    font-weight: 600;
  }

  .title {
    font-size: 1rem;
    color: #919b9b;
  }
}

@media only screen and (max-width: 340px) {
  .card_main_div {
    width: 100%;
    margin-bottom: 10px;
  }

  .amount {
    font-size: 1rem;
    font-weight: 600;
  }

  .title {
    font-size: 0.8rem;
    color: #919b9b;
  }
}
