#painSurveyHeader {
  background-color: white;
  padding: 25px;
  border-radius: 20px;
  margin-bottom: 40px;
}

.questionaire {
  background-color: transparent;
  padding: 6px;
  border-radius: 20px;
  height: fit-content;
  max-height: 65vh;
  overflow: scroll;
}
.searchScreenInput::placeholder {
  color: #919b9b;
  opacity: 0.8;
}
.quesDiv {
  background-color: #fff;
  border-radius: 20px;
  padding: 20px 0px;
  margin-bottom: 30px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.quesSec {
  padding: 20px 25px;
  display: flex;
  width: 40%;
  align-items: center;
  justify-content: space-between;
}

.ansSec {
  padding: 20px 25px;
  display: flex;
  width: 60%;
  align-items: center;
  justify-content: space-between;
}

.leftTxt {
  color: #136773;
  flex-basis: 90%;
  font-weight: 500;
  font-size: 14px;
}

.rightTxt {
  color: #454238;
  flex-basis: 90%;
  font-weight: 500;
  font-size: 14px;
}

.painScale {
  width: 65%;
  height: 8rem;
  display: flex;
  border-radius: 7px;
}
.painScaleColorDiv {
  flex-basis: 10%;
}
.painScaleSimple {
  width: 92%;
  height: 5.5rem;
  display: flex;
  border-radius: 7px;
}
.surveyHeaderName {
  color: #fba622;
  font-weight: 500;
  font-size: 20px;
}
.simpleExcerciseColorsParent {
  display: flex;
  justify-content: space-between;
  width: 92.5%;
  margin-left: 30px;
  margin-top: 24px;
}
.excerciseCompletedQstn {
  justify-content: space-between;
  width: 92.5%;
  margin-left: 30px;
  margin-top: 15px;
  background-color: white;
  border-radius: 16px;
  padding: 12px 20px 20px 12px;
}
.excerciseCompletedQstnNo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 92.5%;
  margin-left: 30px;
  margin-top: 16px;
  background-color: white;
  border-radius: 12px;
  padding: 12px 20px 20px 12px;
}
.colorsDivSimpleExcer {
  width: 32.5%;
  background-color: white;
  border-radius: 16px;
  padding: 18px 10px 20px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.qstnTxtSimpleExcer {
  color: #136773;
  font-size: 16px;
  font-weight: 400;
  margin-left: 12px;
}
.qstnTxtSimpleExcerNo {
  color: #136773;
  font-size: 16px;
  font-weight: 400;
  margin-left: 12px;
  width: 30%;
}
.answerSimpleExcerTxtTwo {
  color: #919b9b;
  font-size: 13px;
  font-weight: 400;
  margin-left: 12px;
  margin-top: 8px;
}
.setsRepsSimpleVal {
  color: #919b9b;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.07cm;
}
.BlueTxtVal {
  font-size: 15px;
  color: #136773;
  font-weight: 600;
}
.setsRepsSimple {
  color: black;
  font-size: 13px;
  font-weight: 400;
}
.answerSimpleExcerTxtTwoNo {
  color: #919b9b;
  font-size: 13px;
  font-weight: 400;
  margin-left: 12px;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 6px;
}
.answerSimpleExcerTxt {
  color: black;
  font-size: 20px;
  font-weight: 500;
  margin-left: 12px;
  margin-top: 8px;
}
.simpleExcerTitles {
  font-size: 13px;
  font-weight: 500;
  color: #136773;
  margin-bottom: 6px;
}
.yellowTxt {
  color: #fba622;
}
.sessionHeader {
  color: #919b9b;
  font-weight: 500;
  font-size: 18px;
}

.topSessionSec {
  width: 23%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sessionDate {
  color: #136773;
  font-weight: 500;
  font-size: 16px;
  background-color: #f6fafb;
  padding: 8px 16px;
  border-radius: 20px;
}

.feedbackLeftTxt {
  color: #fba622;
  font-weight: 500;
  font-size: 12px;
  flex-basis: 8%;
}

.feedbackSec {
  padding: 20px 25px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  text-align: left;
}

.feedbackInput::placeholder {
  color: #f7d29c;
  font-weight: 400;
  font-size: 14px;
}

.feedbackInput {
  color: #fba622;
  font-weight: 500;
  font-size: 14px;
  border: none;
  text-align: left;
  flex-basis: 90%;
}

.feedbackInput:focus {
  outline: none;
}

.bgPainSurvey {
  padding: 2rem 1.5rem 0rem 1.5rem;
  width: 97%;
  height: 87vh;
}

.painSurveyArrow {
  margin-top: 19px;
  display: flex;
  justify-content: center;
  width: 100%;
  cursor: pointer;
}

.arowDownTxt {
  color: red;
  width: fit-content;
  justify-content: center;
  margin: auto;
  padding: 10px 30px;
  border-radius: 20px;
  background-color: rgba(255, 184, 184, 0.169);
}
.noResultTxt {
  color: #919b9b;
  font-family: 'Poppins';
  font-size: 13px;
  margin-top: 10px;
  text-align: center;
  font-weight: 400;
  opacity: 0.9;
}
.painSurvey_submit_btn {
  border: none;
  color: white;
  background-color: #919b9b;
  font-size: 1rem;
  padding: 0.7rem 5rem 0.7rem 5rem;
  border-radius: 15px;
  cursor: pointer;
}
#gauge-chart1 {
  margin-left: 1%;
}
@media only screen and (max-width: 1899px) {
  .topSessionSec {
    width: 33%;
  }

  #painSurveyHeader {
    padding: 20px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1200px) {
  .screenShotTitleDiv {
    gap: 80% !important;
  }
}
