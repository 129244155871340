.clinician_dashboard_main_div {
  width: 100%;
  height: 100vh !important;
  background-color: #f6fafb;
  padding: 2.5rem 3.5rem 0rem 2rem !important;
  position: fixed;
  padding-bottom: 8rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 8vh;
}

.clinician_dashboard_left_div {
  width: 77%;
  max-height: 90vh;
}

.clinician_dashboard_right_div {
  width: 23%;
  max-height: 90vh;
}

.clinician_statsCards_div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  gap: 1rem;
}

.vertical_patient_info_card_div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.vertical_patient_info_card {
  height: 120px;
  width: 49%;
  background-color: white;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

.active_patients_details_div {
  display: flex;
  flex-direction: column;
}

.total_active_patients {
  font-size: 1.4rem;
  font-weight: 600;
}

.active_patients_title {
  color: #919b9b;
  font-size: 1rem;
}

.staff_dashboard_div {
  width: 100%;
  max-height: 90vh;
}

@media screen and (max-width: 1440px) {
  .stats_circle {
    position: relative;
    right: -15px;
  }

  .total_active_patients {
    font-size: 1.2rem;
  }

  .active_patients_title {
    font-size: 12px;
  }
}
