.resetPasswordBtn {
  background-color: #fba622 !important;
  width: 100% !important;
  border-radius: 20px !important;
  padding: 2.2% 0px !important;
  box-shadow: none !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  text-transform: none !important;
}
.BtnExportRep {
  background-color: #fba622 !important;
  width: 100% !important;
  border-radius: 14px !important;
  padding: 2.2% 0px !important;
  box-shadow: none !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  text-transform: none !important;
}
.BtnExportRepClose {
  background-color: white !important;
  width: 100% !important;
  border-radius: 14px !important;
  padding: 1.8% 0px !important;
  box-shadow: none !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  text-transform: none !important;
  border: 1px solid black !important;
  color: black !important;
}
.resetPasswordBtnloading {
  background-color: #dcdbd9 !important;
  width: 100% !important;
  border-radius: 20px !important;
  padding: 2.2% 0px !important;
  box-shadow: none !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  text-transform: none !important;
}

.whiteCircularProgress {
  color: white !important;
  display: flex;
  align-items: center;
}
.BlueCircularProgress {
  color: #136773 !important;
  display: flex;
  align-items: center;
}
.progressContainer {
  display: inline-flex;
  align-items: center;
  margin-right: 9px;
}

.deactivateUserBtn {
  background-color: #136773 !important;
  width: 100% !important;
  border-radius: 20px !important;
  padding: 2.2% 0px !important;
  box-shadow: none !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  text-transform: none !important;
}

.enterEmailTxt {
  color: #919b9b;
  font-size: 14px;
  font-weight: 500;
}

.addPopupSubTxt {
  font-weight: 400;
  margin-bottom: 40px;
  color: #919b9b;
  font-size: 14px;
}

.loginClearIcon {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 1300;
  cursor: pointer;
  color: #fba622;
}

.loginClearIconCopy {
  display: flex;
  justify-content: flex-end;
  z-index: 1300;
  cursor: pointer;
  color: #919b9b;
  margin-top: -3%;
}
.loginClearIconCopyExportRep {
  display: flex;
  justify-content: flex-end;
  z-index: 1300;
  cursor: pointer;
  color: #919b9b;
}
.resetPasswordBtn:hover,
.logInBtn:hover {
  background-color: #faa017 !important;
  box-shadow: 0 2px 20px -5px rgb(190, 124, 37) !important;
}

.deactivateUserBtn:hover {
  background-color: #136773 !important;
  box-shadow: 0 2px 20px -5px rgb(19, 103, 115) !important;
}

.rangeBtn {
  background-color: #fba622 !important;
  width: 100% !important;
  border-radius: 15px !important;
  padding: 5% 0px !important;
  box-shadow: none !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  text-transform: none !important;
}

.spaceOnLeft {
  margin-left: 7px;
}

.date-div .react-datepicker-wrapper {
  border: 1px solid #e1e1e1 !important;
  background-color: white;
  border-radius: 14px;
  margin-top: 9px;
  padding: 2%;
  width: 100%;
}

.date-div2 .react-datepicker-wrapper {
  border: 1px solid #454238 !important;
  background-color: white;
  border-radius: 14px;
  margin-top: 9px;
  padding: 2%;
  width: 100%;
}

.date-div,
.date-div2 {
  height: 20%;
}

.date-div2 .react-datepicker-wrapper:hover {
  border: 1px solid #fba622 !important;
}

.date-div .react-datepicker-wrapper:hover {
  border: 1px solid #454238 !important;
}

.react-datepicker-wrapper .react-datepicker__input-container input {
  border: none;
  font-size: 15px;
  width: 100%;
  outline: none;
  color: #919b9b;
}

.closeBtn {
  border: 2px solid #136773 !important;
  color: #136773 !important;
  font-size: 16px !important;
  border-radius: 14px !important;
  text-transform: none !important;
  padding: 6px 30px !important;
  cursor: pointer !important;
  margin: 10px !important;
  font-weight: 600 !important;
}

.delBtn {
  background-color: #ff5151 !important;
  color: white !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  border-radius: 14px !important;
  text-transform: none !important;
  padding: 8px 40px !important;
  cursor: pointer !important;
  box-shadow: none !important;
}

.closeBtn:hover {
  background-color: #136773 !important;
  color: white !important;
}

.genPresTxt {
  font-weight: 600;
  font-size: 19px;
  color: #454238;
  margin-top: 30px;
}

.genPresTxt_2 {
  font-size: 25px !important;
}

.genSubTxt {
  font-weight: 500;
  font-size: 12px;
  line-height: 25px;
  color: #919b9b;
}
.exportPopupTitle {
  font-weight: 500;
  font-size: 17px;
  line-height: 30px;
  color: #fba622;
}
.headingBarExportRep {
  display: flex;
  width: 91%;
  margin: auto;
  justify-content: space-between;
  align-items: center;
}
.noteAddedByName {
  display: flex;
  width: 78%;
  margin: auto;
  margin-bottom: 0px;
  gap: 4px;
}
.notedAddedPerson {
  color: #136773;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
}
.delBtn:hover {
  background-color: rgb(222, 0, 0) !important;
}

.genderBtn {
  border: 1px solid #e1e1e1 !important;
  background-color: white !important;
  color: #919b9b !important;
  font-size: 14px !important;
  border-radius: 14px !important;
  text-transform: none !important;
  padding: 12px 36px !important;
  cursor: pointer !important;
  margin-right: 15px !important;
  margin-bottom: 15px !important;
  box-shadow: none !important;
  font-weight: 500 !important;
}

.genderBtn:hover {
  border: 1px solid #136773 !important;
  background-color: #136773 !important;
  color: white !important;
}

.selectGender {
  background-color: #136773 !important;
  color: white !important;
  font-size: 14px !important;
  border-radius: 14px !important;
  text-transform: none !important;
  padding: 12px 36px !important;
  cursor: pointer !important;
  margin-right: 15px !important;
  margin-bottom: 15px !important;
  box-shadow: none !important;
  font-weight: 500 !important;
}

.genderTxt {
  color: #8c8c8c;
  font-family: 'Helvetica Neue';
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
}

.delPopuptxt {
  font-size: 20px;
  font-weight: 600;
  margin-top: 40px;
}

.forgetPaswdTxt {
  font-size: 20px;
  font-weight: 600;
  line-height: 2;
  color: #454238;
  padding-top: 3% !important;
}

.forgetPaswdTxtchng {
  font-size: 20px;
  font-weight: 600;
  line-height: 2;
  color: #454238;
  padding-top: 0% !important;
}

.addPopupTxt {
  font-size: 22px;
  font-weight: 600;
  color: #fba622;
  line-height: 1;
}

.pointer {
  cursor: pointer;
}

.imgDivPopup {
  width: 26%;
  height: 140px;
  margin: auto;
}

.imgDivPopupProfile {
  width: 120px;
  height: 120px;
  margin: auto;
  display: flex;
  justify-content: center;
  position: relative;
}

#profilePic {
  display: none;
}

.logoutTxt {
  margin-right: 10px;
}

.logoutBtn {
  border: 1px solid #fba622 !important;
  background-color: white !important;
  color: #fba622 !important;
  width: 100% !important;
  border-radius: 20px !important;
  padding: 2.1% 0px !important;
  box-shadow: none !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  text-transform: none !important;
}

.logoutBtnloading {
  border: 1px solid #dfdcd7 !important;
  background-color: #dcdbd9 !important;
  color: #fba622 !important;
  width: 100% !important;
  border-radius: 20px !important;
  padding: 2.1% 0px !important;
  box-shadow: none !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  text-transform: none !important;
}

.logoutBtn:hover {
  background-color: #fba622 !important;
  color: white !important;
  width: 100% !important;
  border-radius: 20px !important;
  padding: 2.1% 0px !important;
  box-shadow: none !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  text-transform: none !important;
}

.logoutBtn:hover .logoutArrow {
  filter: brightness(0) invert(1);
}

.clinicianPopupTxt {
  font-size: 22px;
  font-weight: 600;
  color: #fba622;
  line-height: 2;
  padding-bottom: 10px;
}

.clinicianDiv:hover .top_sec_avatar_disabled {
  background-color: #136773;
}

.clinicianDiv:hover .topSecDocName_disabled {
  color: #136773;
}

.clinicianSubTxt {
  color: #919b9b;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 40px;
}

.noDisplay {
  display: none;
}

.rangePopupTxt {
  font-size: 22px;
  font-weight: 600;
  color: #fba622;
  line-height: 2;
  text-align: center;
  padding-top: 5%;
}

.rangeSubTxt {
  color: #919b9b;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 40px;
  text-align: center;
}

.rangeRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.rangeRow p {
  width: 35%;
  text-align: left;
  font-weight: 500;
  font-size: 16px;
}

.rangeTopRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.rangeTopRow p {
  width: 35%;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  color: #919b9b;
}

.jointImg {
  margin-top: 35px;
}

.spaceOnRight {
  margin-right: 15px;
}

.sendInviteTxt {
  font-weight: 500;
  font-size: 14px;
}

.arrowIcon {
  width: 20px;
  margin-left: 7px;
}

.addImgIcon {
  position: relative;
  top: -34px;
  right: -34px;
  cursor: pointer;
}

.addDPIcon {
  position: absolute;
  bottom: 0;
  right: 17px;
  cursor: pointer;
}

.popups_avatar {
  background-color: #919b9b !important;
  width: 120px !important;
  height: 120px !important;
  font-size: 32px !important;
}

.profile_popups_avatar {
  background-color: #919b9b !important;
  width: 120px !important;
  height: 120px !important;
  font-size: 32px !important;
  border: 3px solid #faa017 !important;
}

.tryAnotherBtn {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-align: center !important;
  color: #f6fafb !important;
  padding: 15px 35px !important;
  background: #ff8c39 !important;
  border-radius: 20px !important;
  text-transform: capitalize !important;
  margin: auto !important;
  width: 385px !important;
}

.wrngBtn {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-align: center !important;
  color: #f6fafb !important;
  padding: 15px 40px !important;
  background: #ff5151 !important;
  border-radius: 20px !important;
  text-transform: capitalize !important;
  margin: auto !important;
}

.usrAddedBtn {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-align: center !important;
  color: #f6fafb !important;
  padding: 15px 80px !important;
  background: #47b45f !important;
  border-radius: 20px !important;
  text-transform: capitalize !important;
  margin: auto !important;
}

.inactiveBtn {
  background-color: #fba622 !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-align: center !important;
  color: #f6fafb !important;
  padding: 15px 150px !important;
  border-radius: 20px !important;
  text-transform: capitalize !important;
  margin: auto !important;
}

.savePBtn {
  border: 1px solid #fba622 !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-align: center !important;
  color: #fba622 !important;
  background: white !important;
  width: 100% !important;
  padding: 15px 150px !important;
  border-radius: 20px !important;
  text-transform: capitalize !important;
  margin: auto !important;
}

.bgApply {
  background-image: url('../assets/images/popups/smthingWrngBg.svg') !important;
  z-index: 2;
  background-size: 400px;
  background-repeat: no-repeat;
}

.pswdChangeTrack {
  display: flex;
  justify-content: space-between;
  border: 1px solid #e1e1e1;
  border-radius: 25px;
  padding: 17px;
  color: #919b9b;
  font-weight: 400;
  font-size: 14px;
}

.blktxt {
  color: #454238;
}

#boldx {
  font-weight: 600 !important;
}

.passwordChangeTxt {
  color: #fba622;
  cursor: pointer;
}

.inputDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.customInput {
  border: none;
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  color: #454238;
  width: 23rem;
  display: flex;
  align-items: flex-end;
}
.customTextarea {
  width: 88%;
  height: auto;
  outline: none;
  border: none;
}
.customFieldIcon {
  margin-right: 10px;
}

.customInput:focus {
  outline: none;
}

#fontWeight {
  font-size: 14px;
  font-weight: 500;
}
.grayColorClass {
  color: #8c8c8c !important;
  font-size: 14px !important;
}
.flag-dropdown {
  display: none !important;
}

.react-tel-input {
  width: fit-content !important;
  border-radius: 20px !important;
}

.react-tel-input .form-control {
  border: 1px solid #e1e1e1 !important;
  padding: 24px 20px !important;
  border-radius: 20px !important;
  width: 262px !important;
  color: black !important;
}

.edit-phone-input .react-tel-input .form-control {
  border: 1px solid black !important;
  padding: 24px 20px !important;
  border-radius: 20px !important;
  width: 262px !important;
  color: black !important;
}

.react-tel-input .form-control:focus {
  border: 1px solid #454238 !important;
}

.react-tel-input .form-control:hover {
  border: 1px solid #454238 !important;
}

.edit-phone-input .react-tel-input .form-control:focus {
  border: 1px solid #ff9d09 !important;
}

.edit-phone-input .react-tel-input .form-control:hover {
  border: 1px solid #ff9d09 !important;
}

.dummyPlaceholder {
  color: #929696d5;
  font-size: 14px;
  font-weight: 500;
  position: absolute;
  left: 20px;
  pointer-events: none;
  cursor: text;
  top: 17px;
  left: 16px;
}

.placeholerPhone .react-tel-input .form-control {
  color: white !important;
}

.react-tel-input .form-control:focus {
  caret-color: black;
}

.placeholerPhone .react-tel-input .form-control:focus {
  color: black !important;
}

#focus .react-tel-input .form-control {
  z-index: 1300 !important;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: rgb(42, 39, 39);
  color: #fff;
  text-align: center;
  padding: 6px 10px;
  border-radius: 6px;
  width: 90px;
  position: absolute;
  z-index: 1;
  font-size: 12px;
  left: 100px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

@media only screen and (max-width: 1536px) {
  .clinicianPopupTxt {
    font-size: 20px;
    font-weight: 500;
  }

  .jointImg {
    max-height: 25rem;
  }
}
