.cards_div_clinician_pg {
  display: flex;
  flex-direction: row;
  text-align: left;
  justify-content: space-between;
  margin-top: 4%;
  margin-left: 5%;
  width: 74vw;
}

.rightSide_div {
  width: 20%;
}

.gridish_child {
  display: flex;
  width: 100%;
}

.bgClinicianTable {
  background-color: rgb(246, 250, 251);
  height: 100vh;
  width: 60%;
  margin-left: auto;
}

.notxt {
  font-size: 14px;
  font-weight: 400;
  color: #919b9bf0;
  margin-left: 10px;
  background-color: #e4e4e4f0;
  padding: 2px 8px;
  border-radius: 10px;
}

.clinicianScreenCardsDiv {
  width: 53%;
}

.clinicNametxt {
  font-size: 14px;
  font-weight: 400;
}

.clinicNameSubtxt {
  color: #fba622;
  font-weight: 700;
}

.clinic_cell {
  font-size: 1rem !important;
  font-weight: 500 !important;
  color: #454238 !important;
  margin-left: 8px;
  align-self: center;
  text-transform: capitalize;
}

#clinicianTable {
  padding: 1.5rem 0 0.5rem 2rem;
  height: 90vh;
  margin-top: 10vh;
  position: fixed;
  width: auto;
}

#patientsByClinicianTable {
  padding: 2% 0px 2% 0px;
  height: 66vh;
}

.breadCrumb {
  padding: 3% 0 0rem 5%;
  color: #919b9b;
  width: 100%;
  font-size: 14px;
  word-spacing: 5px;
  font-weight: 400;
}

@media only screen and (max-width: 1440px) {
  .clinicianScreenCardsDiv {
    width: 57%;
  }

  .breadCrumb {
    font-size: 10px;
  }
}

@media only screen and (max-width: 1710px) {
  .table_cells {
    padding: 5px;
  }
}

@media only screen and (max-width: 1440px) {
  .clinic_cell,
  .reportTxt,
  .msgTxt {
    font-size: 0.7rem !important;
  }

  .clinicNametxt {
    font-size: 12px;
    width: 100%;
  }
}
